.page1 {
    height: 600px;
    padding-right: 3%;

}
.p1-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; 
  }

.page1-line.title-line-wrapper {
    width: 312px;
    margin: 24px auto 76px;
}
.page1-line.title-line-wrapper .title-line {
    animation: page1TitleLine 3s ease-in-out 1.5s infinite;
}
.page1-bg {
    font-size: 320px;
    color: #ebedf0;
    position: absolute;
    width: 100%;
    text-align: center;
    opacity: .25;
    margin-top: -400px;
    transform: translateY(960px);
}
.page1-box-wrapper {
    display: flex;
    align-items: flex-start;
    /* background-color: aqua; */
    margin-top: 55px;
}
.page1-box-wrapper li {
    width: 33.33%;
    display: inline-block;
    will-change: transform;
}
.page1-box-wrapper li .page1-box {
    flex-grow: 1;
    margin: auto;
    padding: 0 15px;
    text-align: center;
    position: relative;
}
.page1-box-wrapper li .page1-box .page1-image {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin: 20px auto 32px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    transition: box-shadow .3s ease-out, transform .3s ease-out;
}
.page1-box-wrapper li .page1-box:hover .page1-image {
    transform: translateY(-5px);
}
.page1-box-wrapper li .page1-box h3 {
    color: #0d1a26;
    font-size: 20px;
    margin: 8px auto;
}

.page1-box-wrapper li .page1-box p {
    color:rgb(114,131,147);
    font-size: 15px;
}
.page1-point-wrapper {
    position: absolute;
    width: 0;
    left: 50%;
    top: 0;
}
.page1-point-wrapper .point-0 {
    width: 4px;
    height: 4px;
}
.page1-point-wrapper .point-2, .page1-point-wrapper .point-ring {
    width: 10px;
    height: 10px;
}
.page1-point-wrapper .point-ring {
    border-style: solid;
    border-width: 1px;
    background: transparent !important;
}
.page1-point-wrapper .point-1 {
    width: 6px;
    height: 6px;
}
.page1-point-wrapper .point-3 {
    width: 15px;
    height: 15px;
}
.page1-point-wrapper i {
    display: inline-block;
    border-radius: 100%;
    position: absolute;
    opacity: 0;
    transform: translate(0,30px);
}
