.full-screen-layout {
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}

.content-color{
  min-height: 100vh;
  background: #2a394b; 
}

.top-right-component {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}