.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    margin-top: 3%;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
  }

.title{
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the form content horizontally */
  }

.signup-input {
    width: 70%;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid rgb(217,217,217);
    border-radius: 3px;
    box-sizing: border-box; /* Include padding in the width calculation */
  }

.name-container{
    display: flex;
    width: 70%;
    gap: 10px;
}

.DOB-container{
    display: flex;
    width: 70%;
    gap: 10px;
}
.signup-button {
    background-color: rgb(22,119,255);
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 70%; /* Make the button span the full width */
    margin-top: 20px;
    height: 40px;
    font-size: 16px;
  }
.error{
  color: rgb(255, 0, 0);
}
.signup-button:hover {
  background-color: rgb(61, 142, 228);
  }
  
.signup-text{
    font-size: smaller;
    margin-top: 10px;
  }