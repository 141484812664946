/* DataTable.css */

/* 让整个表格居中 */
.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 使用视口高度来垂直居中 */
  }
  
  /* 样式化表格 */
  table {
    border-collapse: collapse; /* 使边框合并为单一线条 */
    width: 80%; /* 表格宽度 */
    margin: 0 auto; /* 水平居中 */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* 添加阴影效果 */
  }
  
  /* 表头样式 */
  th {
    background-color: #4CAF50; /* 背景颜色 */
    color: white; /* 文本颜色 */
    padding: 12px 20px; /* 内边距 */
  }
  
  /* 表格单元格样式 */
  td {
    border: 1px solid #ddd; /* 单元格边框 */
    padding: 8px; /* 内边距 */
  }
  
  /* 鼠标悬停在行上时的样式 */
  tr:hover {
    background-color: #f5f5f5;
  }
  
  /* 表格每行的样式 */
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tr:nth-child(odd) {
    background-color: #fff;
  }
  