.input-icon {
  font-size: 1.5rem;
  margin: 0.5rem 1rem 0.5rem 0;
}

.input-element {
  font-size: 1rem;
}

.name-section {
  display: flex;
  justify-content: space-between;
}
