header {
  padding: 1rem;
  text-align: center;
  height: 100px;
  margin-top: -10px;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
#logo {
  overflow: hidden;
  padding-left: 40px;
  float: left;
  line-height: 64px;
  text-decoration: none;
  height: 64px;
  margin-top: -5px;
}
#logo img {
  display: inline;
  vertical-align: middle;
  margin-right: 16px;
  width: 32px;
}
#logo span {
  outline: none;
  font-size: 14px;
  line-height: 28px;
  color: #666;
}
#logo a {
  text-decoration: none;
}

nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

nav ul {
  list-style: none;
  display: flex;
}
a img{
  margin:0;
  width: 30px;
}
nav ul li a {
  font-size: 16px;
  color: #666;
  margin: 0 1rem;
  transition: all 0.3s ease;
}

nav ul li a:hover {
  color: #f0ad4e;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 4rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px #000;
  letter-spacing: 0.1em;
  font-family: "Lobster", cursive;
}

p {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

button {
  background-color: transparent;
  color: #fff;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #e49d3a;
}

.logo-container {
  display: flex; /* Use flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.logo-container img {
  width: 100px; /* Adjust the width of the logo as needed */
  margin-right: 10px; /* Add some space between the logo and heading */
}

.dropdown-popup {
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.dropdown-menu {
  align-items: center;
  justify-content: center;
  display: list-item;
  list-style: none;
  padding: 0;
  margin: 0;
}
.dropdown-menu li {
  padding: 5px;
}

.logout-popup {
  position: relative;
  height: 28px;
}

.logoutBtn {
  height: 28px;
  color: rgb(96, 150, 230);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoutBtn:hover {
  color: #fff;
}

.logout-menu {
  position: absolute;
  align-items: center;
  justify-content: center;
  display: list-item;
  list-style: none;
  padding-left: 40px;
  text-align: center;
}
.logout-menu li {
  padding-right: 0px;
}
