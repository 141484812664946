/* App.css */

.container {
  min-height: 100vh;
  width: 100%;
}

body {
  overflow-x: hidden; 
  height: 100%; /* Set the height of the background to full screen */
  margin: 0; /* Remove any default margins */
}

.button {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: #008CBA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

#content {
  height: 100%;
}

.background {
  background: white;
  position: fixed;
  top: 0;
  left: 20%;
  right: 0;
  padding: 1rem;
  padding-left: 5%;
}