html, body {
    height: 100%;
}
body {
    line-height: 1.5;
    font-size: 14px;
    background: #fff;
    transition: background 1s cubic-bezier(0.075,0.82,0.165,1);
    overflow-x: hidden;
}

a {
    transition: color .3s ease;
}
a:focus {
    text-decoration: underline;
    text-decoration-skip: ink;
}

svg g {
    transform-origin: 50% 50%;
    transform-box: fill-box;
  }
.banner-wrapper {
    height: 526px;
    width: 100%;
    max-width: 1500px;
    margin: auto;
    position: relative;
}
.banner-wrapper .banner-title-wrapper {
    width: 40%;
    max-width: 480px;
    height: 210px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8%;
    margin: auto;
    z-index: 1;
}
.banner-wrapper .banner-title-wrapper > * {
    will-change: transform;
}
.banner-wrapper .banner-title-wrapper h1 {
    /* font-family: "Futura", "Helvetica Neue For Number", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif ; */
    font-size: 40px;
    margin: 12px 0;
    color: #1890ff;
    text-shadow: none;
}
.banner-wrapper .banner-title-wrapper p {
    font-size: 16px;
    color: rgb(113,141,147);
}
.banner-wrapper .banner-title-wrapper .button-wrapper {
    margin-top: 50px;
    line-height: 40px;
    align-items: center;
    display: flex;
    margin-left:30%;
}
.bannerBtn{
    width: 150px;
}

.banner-wrapper .banner-title-wrapper .button-wrapper .github-btn {
    display: inline-block;
    height: 28px;
}
.banner-wrapper .banner-title-wrapper .button-wrapper .github-btn .gh-btn {
    height: 28px;
    border-radius: 4px;
    background: #f3f3f3;
    background: linear-gradient(to bottom,#ffffff 0%,#f3f3f3 100%);
    border: 1px solid #ebedf0;
    align-items: center;
    display: flex;
    padding: 0 12px;
    font-size: 13px;
}

.banner-wrapper .banner-title-wrapper .title-line {
    transform: translateX(-64px);
    animation: bannerTitleLine 3s ease-in-out 0s infinite;
}
.banner-wrapper .banner-image-wrapper {
    width: 45%;
    max-width: 598px;
    height: 350px;
    position: absolute;
    right: 4%;
    margin: auto;
    top: 0;
    bottom: 0;
    /* opacity: 0; */
    margin-top: 10%;
    margin-left: 8%;
}
.home-banner-image {
    display: none;
}
.title-line-wrapper {
    height: 2px;
    width: 100%;
    overflow: hidden;
    margin-left: 40%;
  }
  
  .title-line {
    height: 100%;
    width: 64px;
    transform: translateX(-64px);
    background: linear-gradient(to right, rgba(24, 144, 255, 0) 0%, rgba(24, 144, 255, 1) 100%);
    animation: bannerTitleLine 3s ease-in-out 0s infinite; 
  }
  
  @keyframes bannerTitleLine {
    0% {
      transform: translateX(-64px); 
    }
    100% {
      transform: translateX(100%); 
    }
  }

.home-page {
    margin: 50px auto;
}
.home-page h2 {
    text-align: center;
    font-weight: 300;
    font-size: 28px;
    color: #314659;
    letter-spacing: 0.6px;
}
.home-page h2 span {
    font-weight: 600;
}
.home-page-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto;
    position: relative;
}
