.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    background-color: #282727;
    width: 20%;
    overflow: auto;
    max-height: 100vh;
  }

.sidebar-button{
    width: 80%;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 10%;
    color: white;
    background-color: #282727;
    border-radius: 5px;
    border: 0.5px dotted lightgray;
}

.interview-button {
  width: 90%;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  padding: 8px 10px;
  margin-top: 10px;
  margin-left: 5%;
  color: white;
  background-color: #282727;
}

.delete-button {
  background-color: transparent;
  padding: 8px;
  margin: 5px; 
  cursor: pointer;
  display: flex;
  flex: 20%;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
}

.delete-button:hover{
  background-color: #ff4136;
}

.delete-button:focus {
  outline: none;
}

.delete-button svg {
  color: white;
  width: 9px;
  height: 9px;
  fill: currentColor;
}

.user-key-input {
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #282727;
}

.interview-title {
  flex: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.interview-button {
  display: flex;
  align-items: center;
}

