.chatContainer {
    padding-left: 25%;
    padding-right: 25%;
    color: #fff;
    max-height: 85vh;
    min-height: 85vh;
    overflow: scroll;
}

.chatContainer-padding-adjust {
    padding-left: 17% !important;
    padding-right: 17% !important;
}

p {
    font-size: 18px; /* Replace 20px with the desired font size */
}

.img {
    vertical-align: middle;
}

.custom-text {
    color: #3a506b;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40%;
}