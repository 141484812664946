.chat-container {
  min-height: 100vh;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.button-container {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0;
  z-index:2;
}

.chat-button {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: #0077c2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1rem;
  width: 100%;
}

.clear-button {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.error-message {
  color: red;
}

.chat-input {
  position: fixed;
  min-height: 3%;
  margin-left: 5%;
  margin-top: 20px;
  width: 55%;
  padding: 1rem;
  bottom: 3rem;
  max-height: 100px;
  overflow: auto;
  background-color: white;
  border-radius: 5px;
  resize: auto;
  z-index: 2;
}

.response-container {
  padding:0 !important;
  height: 500px;
  width: 95%;
  resize: both;
  position: relative;
  overflow: auto;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.response-title {
  color: black;
}

.response-text {
  color: black;
  font-size: 20px;
}