.button-container {
    position: relative; /* This will allow absolute positioning of children relative to this container */
    display: inline-block; /* To make the container fit its content */
}

.content-layout {
    padding-top: 6%;
    padding-left: 3.25%;
}

.button-layout {
    width: 290px;
    height: 150px;
    font-size: large;
    overflow: hidden;
}

.button-layout:hover {
    background-color: #F5F5F5;
    width: 300;
}

.add-button {
    margin-right: 3%;
    float: right;
}

.button-container:hover .delete-button {
    opacity: 1;
    pointer-events: auto;
}

.delete-button {
    position: absolute;
    right: 0;
    top: 16.5%;
    height: 50px;
    width: 50px;
    transform: translateY(-50%);
    background-color: #f44336;
    border: none;
    cursor: pointer;
    opacity: 0; /* Start as invisible */
    transition: opacity 0.3s ease; /* Transition effect */
    pointer-events: none; /* Initially make it non-clickable */
}

.button-container:hover .pin-button {
    opacity: 1;
    pointer-events: auto;
}

.pin-button {
    position: absolute;
    right: 1.5%;
    top: 80%;
    height: 50px;
    width: 50px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0; /* Start as invisible */
    transition: opacity 0.3s ease; /* Transition effect */
    pointer-events: none; /* Initially make it non-clickable */
}

.pin-button:hover {
    border: #4096ff;
    background-color: transparent;
}

.selected-pin-button {
    position: absolute;
    right: 1.5%;
    top: 80%;
    height: 50px;
    width: 50px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.selected-pin-button:hover {
    border: #4096ff;
    background-color: transparent;
}