.profile-wrapper {
  padding: 0 0 5rem 0;
  position: relative;
  top: -2rem;
}

.profile-card {
  width: 50%;
  margin: auto;
}

.input-icon {
  font-size: 1.5rem;
  margin: 0.5rem 1rem 0.5rem 0;
}

.input-element {
  font-size: 1rem;
}

.pwd-section-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.name-section {
  display: flex;
  justify-content: space-between;
}

.default-button:hover {
  background-color: #fff;
}
