.inputContainer {
  padding-left: 24%; 
  padding-right: 20%;  
  padding-top: 3vh;
}

.inputContainer-padding-adjust {
  padding-left: 16% !important;
  padding-right: 14% !important;
}

.components {
  display: inline-flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.text_color {
  color: #1677ff;
}

.radio {
  padding-left: 3%;
  display: flex;
  flex-wrap: nowrap;
}

.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3%;
}

.message-container{
  color : gray;
  font-weight: bold;
  font-size: small;
  text-align: center;
  margin-top: 0px;
}