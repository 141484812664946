/* ILG 5,6,7 from Zhan Zhang */

.elements {
    margin: 20px auto 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 1rem;
  }
  
  .element {
    width: 40%;
    margin: 20px;
    padding: 20px;
    background-color: #f7f7f7;
    display:inline;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .element h3 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .element p {
    font-size: 18px;
    line-height: 1.5;
    color: #666;
  }

  .element h2{
    text-align: center;
    font-size : 30px;
  }

  
  
  