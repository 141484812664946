.sider_button {
  margin: 10px;
  text-align: left;
  background-color: #011627;
  outline: 2px solid #959595;

  &:hover， &:focus{
    /* 
    In order to change back ground color of hover and focus, 
    please refer back to main code inside function New_meeting_button() -> colorPrimaryHover 
    */
    outline: 2px solid #959595;
  }
}

.new_meeting_button {
  width: 175px;
}

.collapse_button {
  margin-left: 0px;
  width: 50px;
}

.history-component {
  max-height: 92vh;
  overflow: scroll;
}