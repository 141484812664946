.header-wrapper {
  display: flex;
}

.pwd-section-title {
  margin: 0;
  flex: 1;
}

.add-btn {
  margin-right: 8px;
}

.prompt-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.prompt-item:hover {
  cursor: pointer;
}
