.container {
  box-sizing: border-box;
  width: 100%;
}

.elements-container {
  display: grid;
  gap: 10px 10px;
  padding: 0px 0px 0px 0px;
}

.element-drag {
  width: 100%;
  padding: 7px;
  height: auto;
  border: 1px solid #959595;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Hide text that overflows the element */
}

.element-drag:hover:not(.selected) {
  background-color: rgb(12, 41, 71);
}

.element-drag:focus {
  border: 2px solid #959595;
  box-shadow: 0 0 4px 1px #959595;
}

.selected {
  background-color: rgb(62, 80, 105);
  border: 2px solid #959595;
}

.componentsGrid {
  display: grid;
  color: antiquewhite;
}

.multiControls {
  display: flex;
  justify-content: space-between;
}

.notesList {
  padding-inline-start: 15px;
  margin: 20px;
}
.clear-all-button {
  font-size: medium;
  width: 45%;
  background-color: #3a506b;
  margin: 10px;
}
.select-all-button {
  margin: 10px;
  font-size: medium;
  width: 45%;
  background-color: #3a506b;
}