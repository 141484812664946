.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  margin-top: 3%;
}

.title {
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form content horizontally */
}

.login-input {
  width: 70%;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid rgb(217,217,217);
  border-radius: 3px;
  box-sizing: border-box; /* Include padding in the width calculation */
}

.login-button {
  background-color: rgb(22,119,255);
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 70%; /* Make the button span the full width */
  margin-top: 20px;
  height: 40px;
  font-size: 16px;
}

.login-button:hover {
  background-color: rgb(61, 142, 228);
}

.login-text{
  font-size: smaller;
  margin-top: 10px;
}
