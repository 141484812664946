.footer {
  background-color: black;
  color: rgba(255, 255, 255, 0.65);
  font-size: 12px;
  padding: 35px 0;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  /* z-index: 999; */
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-links a {
  color: rgba(255, 255, 255, 0.65);
  margin: 0 10px;
  text-decoration: none;
}

@media (max-width: 767px) {
  .footer-links {
    flex-direction: column;
  }

  .footer-links a {
    margin: 5px 0;
  }
}